import m from "mithril";

import { config } from "../config";
import CustomLogging from "../CustomLogging";
import { t } from "../translate";
import { PhotoInfo, PhotoPosition } from "./Photo";

const error = new CustomLogging("error");

export interface EasyDate {
    day: number;
    month: number;
    year: number;
}

enum Season {
    winter,
    spring,
    summer,
    autumn,
    rainy,
    dry,
    "sunny winter", // 1 March – 16 May
    "polar summer", // 17 May – 30 September
    "northern lights winter", // 1 October – 28 February
}

export type SeasonStrings = keyof typeof Season;

export type LinkedPhoto = { id: number; position?: PhotoPosition };

/** GPS model and configuration */
export interface GpsConfig {
    /**
     * Example: 'Garmin 64sc' or 'Garmin 66sr'
     */
    model: string;

    /**
     * Single-band: false, multi-band: true
     */
    multiBandEnabled: boolean;

    /**
     * GPS only: false, GPS+others: true
     * Other constellations include:
     * * GLONASS (64sc + 66sr)
     * * GALILEO (64sc + 66sr + inReach Messenger)
     * * QZSS (66sr + inReach Messenger)
     * * IRNSS (66sr)
     * * BEIDOU (inReach Messenger)
     */
    multiGNSSEnabled: boolean;

    /**
     * Use Wide Area Augmentation System/European Geostationary Navigation
     * Overlay Service (WAAS/EGNOS) data.
     * WAAS/EGNOS is not available on the multi-band Garmin GPSMAP 66sr.
     */
    waasEgnosEnabled: boolean;
}

/** Default GPS configuration when no details are provided in the JSON file. */
const defaultGpsConfig = [
    {
        model: "Garmin 64sc",
        multiBandEnabled: false,
        multiGNSSEnabled: true,
        waasEgnosEnabled: false,
    },
];

export interface BaseStoryInfo {
    /** Start date in the YYYY-MM-DD format. */
    start?: string;

    /** Trip duration in days. Can be half. */
    duration?: number;

    /**
     * The meteorological season, which depend not only on the start date,
     * but also the hemisphere.
     */
    season?: SeasonStrings;

    /**
     * Total number of photos linked to this story. Minimum: 1.
     * Value automatically generated by the Webpack plugin.
     */
    totalPhotos: number;

    /**
     * Photo folder name of the latest photo taken on that trip.
     * Value automatically generated by the Webpack plugin.
     */
    mostRecentPhoto?: number;

    /** Translated story title. */
    title?: string;
}

/** Structure of the JSON file. */
export interface StoryInfo extends BaseStoryInfo {
    /** True if there is a WebTrack to load. */
    hasGeodata?: boolean;

    /** That really depends on the story location. */
    mapExaggeration?: number;

    /**
     * GPS model and configuration. Such information is not included in the
     * GPX file, therefore not included in the WebTrack metadata.
     * This config is skipped if hasGeodata is false.
     */
    gpsConfig?: GpsConfig[];

    /**
     * List of photos linked to the story.
     * Value automatically generated by the Webpack plugin.
     */
    photos: LinkedPhoto[];

    /** Translated story content. */
    content?: string;
}

function getOriginPhotoId(): number | null {
    const id = parseInt(
        String(m.parsePathname(m.route.get()).params.from_photo),
    );
    return isNaN(id) || id > config.firstPhotoId ? null : id;
}

/**
 * Model handling one story.
 * @notExported
 */
export class Story {
    /** Story title retrieved from the Markdown file. */
    title: string | null = null;

    /** Story content (without title) retrieved from the Markdown file. */
    content: string | null = null;

    /** Start date retrieved from the JSON file. */
    start: EasyDate | null = null;

    /** Total number of days, retrieved from the JSON file. */
    duration: number | null = null;

    /** Local season retrieved from the JSON file. */
    season: SeasonStrings | null = null;

    /** True if the story contains a WebTrack, based on the JSON file. */
    hasGeodata = false;

    /** Factor intensifying (if > 1) the Mapbox DEM data. */
    mapExaggeration = 1;

    /** The most recent photo ID of the story, based on the JSON file. */
    mostRecentPhoto: number | null = null;

    /** Total number of photos linked to this story. */
    totalPhotos: number | null = null;

    /** GPS model and configuration, based on the JSON file or default conf. */
    gpsConfig: GpsConfig[] | null = null;

    /** Folder name of the story. */
    folderName: string | null = null;

    /** True if the JSON file has been fetched and processed. */
    gotStoryMeta = false;

    /** JSON file of the linked photo. */
    originPhotoMeta: PhotoInfo | null = null;

    /** List of photos linked to the story. */
    photos: LinkedPhoto[] | null = null;

    /** True if fetching the story metadata returned 404. */
    notFound = false;

    /** True when the data source information are expanded/visible. */
    isDataSourceExpanded = false;

    /** True if a story is available. */
    isLoaded(): boolean {
        return this.gotStoryMeta;
    }

    /** Static method converting a string date like 2020-10-25. */
    static strToEasyDate(strDate: string | undefined): EasyDate | null {
        if (!strDate) {
            return null;
        }
        try {
            const [year, month, day] = strDate.split("-");
            return {
                day: parseInt(day),
                month: parseInt(month),
                year: parseInt(year),
            };
        } catch {
            return null;
        }
    }

    /** Get the photo ID from the URL, otherwise from the story metadata. */
    getActualPhotoId(): number | null {
        return getOriginPhotoId() ?? this.mostRecentPhoto;
    }

    /**
     * The origin photo ID is provided by the URL parameter. If not found, it
     * would be the default photo of the story based on the metadata file.
     * Load the origin photo metadata to be asynchronously inserted in the
     * story page.
     */
    loadOriginPhotoMeta(): void {
        const originPhotoId = this.getActualPhotoId();
        if (!originPhotoId) {
            return;
        }
        m.request<PhotoInfo>({
            method: "GET",
            url: "/content/photos/:folderName/_/i.json",
            params: { folderName: originPhotoId },
        }).then((result) => {
            this.originPhotoMeta = result;
        });
    }

    /** Load the story identified by its ID, or do nothing if not existing. */
    reload(): void {
        if (this.folderName) {
            this.load(this.folderName);
        }
    }

    /** Load a story from a specific folder (fields are null if not found). */
    load(folderName: string): void {
        this.notFound = false;
        this.gotStoryMeta = false;
        this.originPhotoMeta = null;
        this.photos = null;
        this.folderName = folderName;
        m.redraw();
        m.request<StoryInfo>({
            method: "GET",
            url: "/content/stories/:folderName/_/i.:lang.json",
            params: { folderName, lang: t.getLang() },
        })
            .then((result) => {
                if (result.start) {
                    this.start = Story.strToEasyDate(result.start);
                } else {
                    this.start = null;
                }
                if (result.season !== undefined) {
                    if (Season[result.season] !== undefined) {
                        this.season = result.season;
                    } else {
                        error.log(`Unknown season '${String(result.season)}'`);
                        this.season = null;
                    }
                } else {
                    this.season = null;
                }
                this.title = result.title ?? null;
                this.content = result.content ?? null;
                this.duration = result.duration ?? null;
                this.hasGeodata = result.hasGeodata ?? false;
                this.mostRecentPhoto = result.mostRecentPhoto ?? null;
                this.totalPhotos = result.totalPhotos ?? null;
                this.photos = result.photos ?? null;
                this.mapExaggeration = result.mapExaggeration ?? 1;
                if (this.hasGeodata) {
                    this.gpsConfig = result.gpsConfig ?? defaultGpsConfig;
                } else {
                    this.gpsConfig = null;
                }
                this.gotStoryMeta = true;
                this.loadOriginPhotoMeta();
            })
            .catch((error: Error & { code: number }) => {
                if (error.code === 404) {
                    this.notFound = true;
                }
                this.start = null;
                this.gotStoryMeta = true;
                this.hasGeodata = false;
            });
    }

    /** Path to the photo of the loaded story. */
    getPhotoPath(): string | null {
        const originPhoto = this.getActualPhotoId();
        if (!originPhoto) {
            return null;
        }
        return m.buildPathname("/:lang/photo/:id", {
            lang: t.getLang(),
            id: originPhoto,
        });
    }
}

/** This is a shared instance. */
export const story = new Story();
